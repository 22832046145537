<!--
  Display Market Index Artist/Artwork Toggle
-->
<template>
  <div class="market-header">
    <Typography variant="pc-title-48 desktop-nav-only">Market</Typography>
    <div class="tabs">
      <div
        class="tabs__item"
        :class="{ active: isArtistRoute }"
        @click="gotoArtist"
      >
        Artist
      </div>
      <div
        class="tabs__item"
        :class="{ active: !isArtistRoute }"
        @click="gotoArtwork"
      >
        Artwork
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isArtistRoute () {
      const routeName = this.$route.name
      return routeName.startsWith('collection')
    }
  },

  methods: {
    gotoArtist () {
      this.$router.push('/artists')
    },
    gotoArtwork () {
      this.$router.push('/market')
    }
  }
}
</script>

<style lang="scss" scoped>
.market-header {
  display: flex;
  align-items: center;
  .tabs {
    flex-grow: 1;
    text-transform: uppercase;
    display: flex;

    @include desktop {
      justify-content: flex-end;
    }

    .tabs__item {
      cursor: pointer;
      padding-bottom: 5px;

      font-family: Untitled Sans;
      font-style: normal;

      @include mobile {
        margin-right: 18px;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        color: #666666;
      }

      @include desktop {
        font-weight: bold;
        font-size: 18px;
        line-height: 130%;

        margin-left: $space-l;
      }

      &.active {
        border-bottom: solid 4px white;
        color: white;
      }
    }
  }
}
</style>

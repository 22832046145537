<!-- Displays all collection groups associated with this market. -->
<template>
  <div>
    <MarketTabs />
    <div class="collection-groups-container" :data-number="collectionGroups ? collectionGroups.length : 0">
      <div
        v-for="(collectionGroup, index) in collectionGroups"
        :key="`collection-group-${index}`"
        class="collection-group-single"
      >
        <router-link :to="`${collectionGroup.id}`" append>
          <div class="collection-group-image" :style="`background-image: url(${collectionGroup.image})`">
          </div>
          <h4 class="collection-group-name">{{ collectionGroup.name }}</h4>
          <p v-if="collectionGroup.numCollections" class="collection-count">
            {{ collectionGroup.numCollections }} Collection{{ collectionGroup.numCollections > 1 ? 's' : '' }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarketTabs from '@/components/MarketTabs'

export default {
  computed: {
    ...mapGetters([
      'collectionGroups'
    ])
  },
  components: {
    MarketTabs
  }
}
</script>

<style lang="scss">
.collection-groups-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  margin-bottom: 50px;

  @include desktop {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(auto, auto);
    gap: $space-ll $space-m;
    padding-bottom: $space-xl;
  }
}

.collection-group-single {
  cursor: pointer;
  text-align: center;
  a {
    color: white;
    text-decoration: none;
  }
  .collection-group-image {
    @include bg-cover-center;
    // margin-bottom: $space-l;

    @include mobile {
      height: 152px;
    }

    &::after {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
}

.collection-group-name {
  font-family: $font-title;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-ml;
  line-height: 120%;
  text-transform: uppercase;
  margin-top: $space-s;

  @include desktop {
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.collection-count {
  font-family: Drive Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #DDDDDD;
  text-transform: uppercase;
}
</style>
